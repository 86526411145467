import React, { Component } from 'react'
import styled from '@emotion/styled';
import { config, theme } from '@packages/fdpq-core/config/atc';
import { FdpqWidget } from '@packages/fdpq-widget';


const GlobalStyleProvider = styled.div`
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Roboto Light, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
  Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji,
  Segoe UI Symbol;
`;

export default class rhtest extends Component {
  render() {
    const name = 'carloss';
    const options = [
      {
        value: 'Nissan',
        text: 'Nissan'
      },
      {
        value: 'Subaru',
        text: 'Subaru'
      }
    ]
    return (
      <GlobalStyleProvider>
        <div>
          <FdpqWidget initialData={{
            theme, config, 
            navigation: {
              currentPage: 'THANK_YOU_PAGE'
            },
            location: {
              zipcode: '00000'
            },
            vehicles: {
              selectedVehicle: {
                makeId: 'dummyMakeId',
                makeName: 'dummyMakeName',
                trimId: 123456,
                trimName: 'dummytrimName',
                modelId: 'dummyModelId',
                modelName: 'dummyModelName',
                yearId: 2000
              }
            }
          }} />
        </div>
      </GlobalStyleProvider>
    )
  }
}
